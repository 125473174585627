import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={31}
    fill="none"
    viewBox="0 0 26 31"
    preserveAspectRatio="meet"
    {...props}
  >
    <path
      fill={props.fill || "#fff"}
      d="M21.027 16.127c-.034-3.767 3.068-5.6 3.21-5.686-1.757-2.575-4.48-2.926-5.437-2.955-2.287-.241-4.505 1.376-5.67 1.376-1.188 0-2.982-1.352-4.915-1.312-2.488.038-4.816 1.486-6.092 3.735-2.635 4.586-.67 11.324 1.854 15.03 1.263 1.817 2.738 3.843 4.67 3.771 1.888-.077 2.594-1.21 4.874-1.21 2.259 0 2.921 1.21 4.89 1.165 2.028-.032 3.305-1.824 4.523-3.655 1.46-2.08 2.045-4.13 2.069-4.235-.047-.016-3.937-1.51-3.976-6.024Zm-3.72-11.079c1.015-1.277 1.71-3.015 1.518-4.78-1.471.066-3.31 1.024-4.37 2.273-.936 1.101-1.774 2.906-1.557 4.603 1.652.124 3.348-.838 4.408-2.096Z"
    />
  </svg>
);
export default SvgComponent;
